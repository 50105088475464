<template>
  <div :style="$vuetify.breakpoint.mdAndUp ? 'height:100%;' : ''">
    <!--    <span>this is new desktop referral ui</span>-->
    <v-row no-gutters>
      <v-col cols="12">
        <!--        <v-img :src="`${mediaUrl}/banner/referral-banner-desktop.jpg`"></v-img>-->
      </v-col>
    </v-row>

    <v-card class="desktop_referral_card hidden-sm-and-down afrLoginContainer">
      <!--      <v-card-subtitle>refer and earnr</v-card-subtitle>-->
      <v-card-title class="d-none">
        <!--        <v-img class="" style="border-radius: 10px" :src="`${mediaUrl}/banner/referral-banner-desktop.jpg`">-->
        <!--          <div class="referral-header-content fill-height">-->
        <!--            <v-row no-gutters class="fill-height align-content-end">-->
        <!--              <v-col cols="6">-->
        <!--                <div class="ml-3">-->
        <!--                  <span>{{ $t(`label.referralProgram`) }}</span>-->
        <!--                  <p></p>-->
        <!--                </div>-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </div>-->
        <!--        </v-img>-->
      </v-card-title>
      <div class="afterLogin">
        <div>
          <v-row no-gutters class="px-4" style="position:relative;">
            <img loading="lazy" src="/static/image/referral/topLeft-coin.png" class="topLeft-coin" />
            <img loading="lazy" src="/static/image/referral/bottomLeft-coin.png" class="bottomLeft-coin" />
            <img loading="lazy" src="/static/image/referral/topRight-coin.png" class="topRight-coin" />
            <img loading="lazy" src="/static/image/referral/bottomRight-coin.png" class="bottomRight-coin" />

            <v-img :src="`/static/image/referral/referralinnerbanner-desktop.jpg`" class="newReferralBanner-desktop" :aspect-ratio="87 / 16">
              <!--            <v-badge top right offset-x="5%" offset-y="17%" color="light" class="inner-banner-badge">-->
              <!--              <template v-slot:badge>-->
              <!--                <v-img src="/static/image/referral/info-icon.svg" width="25" height="25" style="cursor:pointer;"></v-img>-->
              <!--              </template>-->

              <!--            </v-badge>-->
              <!--            <div style="position: absolute;top:10px;right:10px"><v-img src="/static/image/referral/info-icon.svg" width="25" height="25" style="cursor:pointer;"></v-img></div>-->
              <div class="banner-desc">
                <span>{{ $t(`label.newReferralBannerMsg1`) }}</span>
                <h1>{{ $t(`label.newReferralBannerMsg2`) }}</h1>
                <span>{{ $t(`label.newReferralBannerMsg3`) }}</span>
              </div>
            </v-img>
            <v-row no-gutters class="subpages pt-3" v-if="isLoggedIn">
              <v-col cols="12" class="text-left">
                <v-btn
                  plain
                  class="referral-tab"
                  :class="this.$route.name === routeName.PROFILE_REFERRAL_TIER ? 'active-btn' : 'inactive-btn'"
                  :to="{ name: routeName.PROFILE_REFERRAL_TIER, params: { lang: this.$route.params.lang } }"
                >
                  <span>{{ $t(`mobileMenuTitle.myReferral`) }}</span>
                </v-btn>
                <!--            <v-divider vertical></v-divider>-->
                <v-btn
                  plain
                  class="referral-tab"
                  :class="this.$route.name === routeName.PROFILE_REFERRAL_REPORT ? 'active-btn' : 'inactive-btn'"
                  :to="{ name: routeName.PROFILE_REFERRAL_REPORT, params: { lang: this.$route.params.lang } }"
                >
                  <span>{{ $t(`mobileMenuTitle.referralReport`) }}</span>
                </v-btn>
                <!--            <v-divider vertical></v-divider>-->

                <v-btn
                  plain
                  class="referral-tab"
                  :class="this.$route.name === routeName.PROFILE_REFERRAL_REDEEM_HISTORY ? 'active-btn' : 'inactive-btn'"
                  :to="{ name: routeName.PROFILE_REFERRAL_REDEEM_HISTORY, params: { lang: this.$route.params.lang } }"
                >
                  <span>{{ $t(`mobileMenuTitle.redeemHistory`) }}</span>
                </v-btn>
                <!--            <v-divider vertical></v-divider>-->

                <v-btn
                  plain
                  class="referral-tab"
                  :class="this.$route.name === routeName.PROFILE_REFERRAL_DOWNLINE_HISTORY ? 'active-btn' : 'inactive-btn'"
                  :to="{ name: routeName.PROFILE_REFERRAL_DOWNLINE_HISTORY, params: { lang: this.$route.params.lang } }"
                >
                  <span>{{ $t(`mobileMenuTitle.downlineReport`) }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </div>
      <v-card-subtitle v-if="!isLoggedIn">
        <!--        <p class="referral-subtitle">{{ $t(`label.referralProgramText`) }}</p>-->
        <div v-if="!isLoggedIn">
          <v-row class=" px-4 pt-2" no-gutters>
            <v-col cols="12">
              <span style="font-weight: bold">{{ $t(`label.newReferralBeforeLogin`) }}</span>
            </v-col>
          </v-row>
          <v-row class=" px-4 pt-2" no-gutters>
            <v-col cols="12">
              <v-btn :class="'primary-button mr-6 newMobileSecondary subtitle-1 full-width white--text pa-2 theme-button embedded-button '" @click="goToRegisterPage">
                {{ $t(`label.signUp`) }}
              </v-btn>
              <v-btn height="auto" @click="goToLoginPage" class="embedded-button primary-button buttonPrimary embedded-login-button pa-2 black--text subtitle-1">
                {{ $t(`button.login`) }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-subtitle>
      <v-card-text class="pa-0 pt-1">
        <div class="afterLogin" style="min-height: 440px;">
          <v-row no-gutters>
            <v-col cols="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="justify-space-around referralLayout hidden-md-and-up">
      <v-img src="/static/image/referral/referral_banner.png" :aspect-ratio="1820 / 420">
        <!-- <div class="fill-height"> -->
        <v-row class="" no-gutters justify="end">
          <v-col cols="12">
            <v-expansion-panels accordion flat class="mobile-referral-expansion">
              <v-expansion-panel>
                <v-expansion-panel-header class="white--text">
                  <label class="white--text">{{ $t(`label.referralProgram`) }}</label>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="white--text subtitle-2 elevation-0">
                  {{ $t(`label.referralProgramText`) }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-img>
      <v-row class="mobile-header no-gutters">
        <v-col cols="6" class="text-center mobile-header-item" :class="$route.name == routeName.REFERRAL_TIER || routeName.REFERRAL_TIER ? 'selected' : ''" @click="naviToTier">
          <router-link :to="routeName.REFERRAL_TIER" style="text-decoration: unset !important">
            <span>{{ $t(`mobileMenuTitle.myReferral`) }}</span>
          </router-link>
        </v-col>
        <v-col
          cols="6"
          class="text-center mobile-header-item"
          :class="$route.name == routeName.REFERRAL_SUMMARY || $route.name == routeName.REFERRAL_DOWNLINE_REPORT ? 'selected' : ''"
          @click="naviToSummary"
        >
          <router-link :to="routeName.REFERRAL_SUMMARY" style="text-decoration: unset !important">
            <span>{{ $t(`mobileMenuTitle.myReferralCom`) }}</span>
          </router-link>
        </v-col>
      </v-row>
      <v-card-text v-if="isLoggedIn || currentRouteName == 'tier'" class="referralContainer">
        <router-view></router-view>
      </v-card-text>
      <v-card-text v-else class="referralContainer">
        <!--        desktop before login view-->
        <v-row v-if="!isLoggedIn" class="hidden-sm-and-down">
          <v-col cols="12" class="text-center">
            <label color="title_color2">{{ $t(`message.referralNoLogin`) }}</label>
          </v-col>
          <v-col cols="12" class="text-center hidden-sm-and-down">
            <v-btn height="auto" @click="goToLoginPage" color="title_color" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold yellow--text subtitle-1">
              {{ $t(`button.login`) }}
            </v-btn>
            <app-button
              :customClass="'buttonPrimary subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button'"
              :action="goToRegisterPage"
              :title="$t(`label.signUp`)"
            ></app-button>
          </v-col>
          <v-col cols="12" class="text-center hidden-md-and-up">
            <v-btn @click="this.goToLoginPage" color="title_color" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">{{ $t(`button.login`) }}</v-btn>
            <app-button :customClass="'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.goToRegisterPage" :title="$t(`label.signUp`)"></app-button>
          </v-col>
        </v-row>
        <!--        mobile beforeLogin view-->
        <v-card class="mobile-refLayout-before-login-card hidden-md-and-up">
          <!--          <v-col cols="6">{{ $t(`message.referralNoLogin`) }}</v-col>-->
          <!--          <v-card-subtitle>{{ $t(`message.referralNoLogin`) }}</v-card-subtitle>-->
          <v-row no-gutters>
            <v-col cols="12" class="mobile-beforeLogin-text">{{ $t(`message.referralNoLogin`) }}</v-col>
            <v-col cols="12">
              <v-row style="flex-direction: column;gap: 1rem;">
                <v-col cols="7" class="mx-auto inpage-before-login-first text-center beforeLoginBtn" @click="goToRegisterPage()">
                  <b>{{ $t(`label.register`) }}</b>
                </v-col>
                <v-col cols="7" class="mx-auto  inpage-navigator-before-login-second text-center beforeLoginBtn" @click="goToLoginPage()">
                  <b>{{ $t(`label.login`) }}</b>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--            <v-card-subtitle cols="12">{{ $t(`message.referralNoLogin`) }}</v-card-subtitle>-->
          <!--        <v-btn>signup</v-btn>-->
          <!--          <v-btn>login</v-btn>-->
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale, uiHelper } from '@/util'
import { REFERRAL_GET_OVERVIEW } from '@/store/referral.module'

export default {
  name: 'appProfileReferral',
  data: () => ({
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    routeName: ROUTE_NAME,
    tierList: [
      {
        title: locale.getMessage(`label.tier1`),
        percent: '1',
        currentTier: true,
        downlineCount: 0
      },
      {
        title: locale.getMessage(`label.tier2`),
        percent: '0.7',
        downlineCount: 0
      },
      {
        title: locale.getMessage(`label.tier3`),
        percent: '0.3',
        downlineCount: 0
      }
    ],
    overview: {},
    referralCode: ''
  }),
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    totalDownlineCount() {
      let total = 0

      this.tierList.forEach(_tier => {
        total += _tier.downlineCount
      })

      return total
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    referralOverview() {
      return this.$store.state.referral.overview
    }
  },
  watch: {
    isLoggedIn() {
      this.getReferralOverview()
    },
    referralOverview() {
      this.overview = this.$store.state.referral.overview

      this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code

      this.tierList[0].downlineCount = this.overview.downline_lv1
      this.tierList[1].downlineCount = this.overview.downline_lv2
      this.tierList[2].downlineCount = this.overview.downline_lv3

      this.tierList[0].percent = this.getConfig(1)
      this.tierList[1].percent = this.getConfig(2)
      this.tierList[2].percent = this.getConfig(3)

      this.referralCode = this.overview.my_referral_code
    }
  },
  created() {
    this.getReferralOverview()
  },
  methods: {
    naviToTier() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_TIER,
        params: { lang: this.$route.params.lang }
      })
    },
    naviToSummary() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_SUMMARY,
        params: { lang: this.$route.params.lang }
      })
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) {
        if (_.isEmpty(this.$store.state.referral.overview)) {
          this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
        } else {
          this.referralCode = this.$store.state.referral.overview.my_referral_code
        }
      }
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    }
  }
}
</script>

<style lang="scss">
.afterLogin {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.afrLoginContainer {
  box-shadow: none !important;
  background-color: transparent !important;
}
.inactive-btn {
  background-color: #f3f5f6;
  border-radius: 25px;
}
.mobile-refLayout-before-login-card.v-card {
  background-color: #1d1d1d;
  text-align: center;
  display: flex;
  height: 170px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 10px;
  color: #ffffff;

  .beforeLoginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .inpage-before-login-first {
    color: black;
    background-color: var(--v-newMobilePrimary-base);
    border-radius: 15px;
    height: 50px;
  }

  .inpage-navigator-before-login-second {
    color: white;
    background-color: var(--v-newMobileSecondary-base);
    border-radius: 15px;
    height: 50px;
  }
  .v-card__subtitle {
    color: #ffffff;
  }

  .mobile-beforeLogin-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.referralCode-card {
  border-radius: 15px !important;
  border: 1px dashed #1d1d1d !important;
}

.share-card {
  background-color: #0066d1 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .theme--light.v-card {
    color: #ffffff;
  }

  border-radius: 15px !important;
}

.mobile-header {
  background-color: #000000;
  color: white;
  height: 50px;
  .mobile-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      border-bottom: 5px solid var(--v-newMobilePrimary-base);
      color: var(--v-newMobilePrimary-base);
    }
  }
}

@media (max-width: 959px) {
  .bank-mid {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
  .margin-desktop {
    width: 100%;
    margin: unset;
  }
}

.embedded-button {
  background-color: var(--v-newMobileSecondary-base);
  min-height: 40px;
  width: 130px !important;
  padding: 4px !important;
  text-transform: none !important;
}

.inner-banner-badge {
  width: 100%;
  height: 100%;
}
.topLeft-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  left: -20px;
  top: -30px;
  z-index: 1;
}

.bottomLeft-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  left: -50px;
  bottom: -65px;
  z-index: 1;
}

.topRight-coin {
  position: absolute;
  width: 55px;
  height: 50px;
  right: 50px;
  top: -30px;
  z-index: 1;
}

.bottomRight-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  right: -20px;
  bottom: -30px;
  z-index: 1;
}

.newReferralBanner-desktop {
  max-height: 160px;
  border-radius: 10px;

  .banner-desc {
    display: flex;
    justify-content: end;
    height: 100%;
    padding-left: 12px;
    flex-direction: column;
    font-size: 0.85rem;
    padding-bottom: 5px;
  }
}

.desktop_referral_card {
  border-radius: 12px !important;
  height: 100% !important;
  font-family: 'Roboto', sans-serif;
  margin-left: 16px;
  overflow-y: unset;

  .referral-header-content {
    color: #ffffff;
  }
  .referral-subtitle {
    margin-top: 16px;
    font-weight: bold;
  }
  .referral-tab {
    text-transform: capitalize;
    font-family: 'Roboto-Medium', sans-serif;
    color: #000;
    margin: 0 8px 0 8px;
  }

  .v-divider--vertical {
    vertical-align: bottom !important;
  }

  .active-btn {
    background-color: var(--v-newMobilePrimary-base);
    color: #000;
    border-radius: 25px;
  }
}

.referralCode-card {
  border-radius: 15px !important;
  border: 1px dashed #1d1d1d !important;
}

.share-card {
  background-color: #0066d1 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .theme--light.v-card {
    color: #ffffff;
  }
  border-radius: 15px !important;
}
.game-menu-image {
  background-color: #ffffff;
  -webkit-mask-image: var(--src);
  mask-image: var(--src);
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-left: 8px;
}
</style>
